<template>
    <main>
        <h1>Estimator Material Costs</h1>
        <table class="table-responsive table">
            <thead>
            <tr>
                <th>Item</th>
                <th>Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="result in results" v-bind:key="result.label">
                <td>{{ result.label }}</td>
                <td><input @keypress.enter="saveItem(result, 'price')" type='text' v-model="result.price"
                           class="form-control number"/></td>
            </tr>
            </tbody>
        </table>
        <h3>History</h3>
        <div>
            <table class="table-responsive table">
                <thead>
                <tr>
                </tr>
                </thead>
                <tbody>
                <tr v-for="result in history_results" v-bind:key="result">
                    <td>{{ result }}</td>
                </tr>
                </tbody>
            </table>
        </div>


    </main>
</template>

<script>
import {store} from '@/store/Tool.store';


export default {
    name: "EstimatorMaterialCosts",
    data() {
        return {
            results: {},
            history_results: {},
        }
    },
    created() {
        this.search();
        this.history_search();
    },
    methods: {
        search: function () {
            store.api('/curb_estimator/material_cost_json')
                .then((results => this.results = results.estimator_material_cost));
        },
        history_search: function () {
            store.api('/curb_estimator/history_json')
                .then(results => this.history_results = results.history);
        },
        saveItem: function (item) {
            store.api('/curb_estimator/save_material_price', {'params': item}).then(() => {
                this.history_search();
            });
        },
    }
}
</script>

<style scoped>

</style>